// import { useIntlDisplayNamesPolyfill } from 'publisher/hooks/use-intl-display-names-polyfill'
import {
  ErrorBoundary,
  Provider as RollbarProvider,
  LEVEL_ERROR,
  LEVEL,
} from '@rollbar/react'
import React, { Suspense } from 'react'
import { useSSR } from 'react-i18next'
import { Store } from 'redux'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import {
  BaseEntityInterface,
  EntityInnerItemInterface,
} from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import App from 'publisher/app'
import i18n from 'publisher/i18n-client'
import { RootState } from 'publisher/store'

export const rollbarConfig = (pageId: number, reportLevel: LEVEL) => ({
  enabled: process.env.NODE_ENV !== 'development',
  accessToken: process.env.ROLLBAR_PUBLISHER_KEY,
  captureUncaught: false,
  captureUnhandledRejections: false,
  autoInstrument: true,
  payload: {
    host: process.env.SYSTEME_IO_HOST,
    environment: process.env.APP_ENV,
    context: pageId,
  },
  reportLevel,
})

interface BaseAppProps {
  store: Store<RootState>
  createPage: (pageType: PageTypeEnum) => React.ReactNode
  createEntityElement: (
    entity: BaseEntityInterface | OldEntityInterface | EntityInnerItemInterface,
  ) => React.ReactNode
  rollbarReportLevel?: LEVEL
}

function BaseApp({
  store,
  createPage,
  createEntityElement,
  rollbarReportLevel = LEVEL_ERROR,
}: BaseAppProps) {
  // useIntlDisplayNamesPolyfill(store.getState().page.locale)

  useSSR(window.initialI18nStore, window.initialLanguage, { i18n })
  return (
    <RollbarProvider
      config={rollbarConfig(store.getState().page.id, rollbarReportLevel)}
    >
      <ErrorBoundary>
        <Suspense fallback={<div>loading ...</div>}>
          <App
            store={store}
            createPage={createPage}
            createEntityElement={createEntityElement(
              store.getState().page.type,
            )}
          />
        </Suspense>
      </ErrorBoundary>
    </RollbarProvider>
  )
}

export default BaseApp
